import APIcon from './icons/APadvantplay.svg';
import HabaneroIcon from './icons/Habanero.svg';
import HKBIcon from './icons/HKBgaming.svg';
import PGSoftIcon from './icons/PGsoft.svg';
import PTIcon from './icons/playtech.svg';
import PPIcon from './icons/pragmatic.svg';
import PTGIcon from './icons/ptgaming.svg';
import SABAIcon from './icons/saba.svg';
import SBOIcon from './icons/sbobet.svg';
import SBIcon from './icons/sexybuccarat.svg';
import SGIcon from './icons/spadegaming.svg';
import SAIcon from './icons/SAgaming.svg';

const GameProviderImage = ({ gameCategoryName, gpName, className, ...props }) => {
	const list = {
		// JKR: { Component: SGIcon, className: 'fill-container-color-primary' },
		HB: { Component: HabaneroIcon, className: 'fill-container-color-primary' },
		SBO: { Component: SBOIcon, className: 'fill-container-color-primary' },
		SABA: { Component: SABAIcon, className: 'fill-container-color-primary' },
		SA: { Component: SAIcon, className: 'fill-container-color-primary' },
		PP: { Component: PPIcon, className: 'fill-container-color-primary' },
		PG: { Component: PGSoftIcon, className: 'fill-container-color-primary' },
		PTG: { Component: PTGIcon, className: 'fill-container-color-primary' },
		SB: { Component: SBIcon, className: 'fill-container-color-primary' },
		AP: { Component: APIcon, className: 'fill-container-color-primary' },
		HKB: { Component: HKBIcon, className: 'fill-container-color-primary' },
		PT: { Component: PTIcon, className: 'fill-container-color-primary' },
		SG: { Component: SGIcon, className: 'fill-container-color-primary' }
	};

	let iconData = list?.[gameCategoryName];
	if (!iconData) {
		// iconData = { Component: '', className: 'fill-container-color-primary' };
		return <p className="text-container-color-primary">{gpName}</p>;
	}

	const { Component: IconComponent, className: iconClassName } = iconData;
	const combinedClassName = `${iconClassName} ${className || ''}`.trim();

	// if (iconData) {
	// 	return <p {...props}>{gameCategoryName}</p>;
	// }
	return <IconComponent className={combinedClassName} {...props} />;
};

export default GameProviderImage;
